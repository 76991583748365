import { Component, OnInit } from '@angular/core';

import { OcppLog } from 'src/app/ocpp/types/ocpp-log';
import { OcppActionEnum } from '../../ocpp/enums/ocpp-action-enum';
import { ChargingStation } from 'src/app/charging-station/charging-station';
import { ChargingStationStatusEnum } from 'src/app/charging-station/charging-station-status.enum';
import { ChargingStationConfig } from 'src/app/charging-station/charging-station-config';
import { ChargingStationErrorCodeEnum } from 'src/app/charging-station/charging-station-error.enum';
import { OcppAuthorizeStatusEnum } from 'src/app/ocpp/enums/ocpp-authorize-status.enum';
import { ChargingStationOcppErrorCodeEnum } from 'src/app/charging-station/charging-station-ocpp-error.enum';
import { DataTransferRequest } from 'src/app/ocpp/dto/DataTransfer';
import { OcppRequestPayloadType } from 'src/app/ocpp/types/ocpp-request-payload.type';
import { Utils } from 'src/app/utils';
import { OcppMessageDirection } from 'src/app/ocpp/enums/ocpp-message-direction.enum';
import { OcppStatusEnum } from 'src/app/ocpp/enums/ocpp-status.enum';

@Component({
  selector: 'app-charging-station',
  templateUrl: './charging-station.component.html',
  styleUrls: ['./charging-station.component.scss'],
})
export class ChargingStationComponent implements OnInit {
  blueColor = '#369';
  messageCount = 0;
  connectorLocked = false;
  heartbeatIntervalId: any = null;
  meterValuesIntervalId: any = null;

  centralSystemUrl = 'ws://localhost:9000/DRSYHFYZSY65G';
  idTag = 'zve30s17p93oihdmtoaw';
  consoleLines: string[] = [];
  statusNotification = OcppStatusEnum.Available;
  meterValuesInterval = 5;
  chargeRate = 3600;
  payloadText = '{}';
  action!: OcppActionEnum;
  isConnected: boolean = false;
  isAuthorized?: boolean = undefined;
  chargingStation?: ChargingStation;

  async ngOnInit() {
    await this.testCs();
  }

  async onConnectOrDisconnect() {
    if (this.chargingStation) {
      this.chargingStation.powerOff();
      this.chargingStation = undefined;
      this.isConnected = false;
      this.isAuthorized = undefined;
    } else {
      const config: ChargingStationConfig = {
        centralSystemUrl: this.centralSystemUrl,
        connectToCentralSystemTimeoutSeconds: 10,
        maxPowerKw: this.chargeRate,
        enableConsoleLog: false,
        retryBootNotificationDefaultWaitSeconds: 10,
        meterValuesIntervalSeconds: this.meterValuesInterval,

        // Official OCPP configurations
        chargePointVendor: 'LRT e-mobility GmbH',
        chargePointModel: 'Smart Vibe',
        chargePointSerialNumber: 'avt.001.13.1',
        chargeBoxSerialNumber: 'avt.001.13.1.01',
        firmwareVersion: '1.4.0',
        iccid: '',
        imsi: '',
        meterType: 'AVT NQC-ACDC',
        meterSerialNumber: 'avt.001.13.1.01',
      };

      const onStatusChange = (status: ChargingStationStatusEnum) => {
        console.log('status:', JSON.stringify(status));
        if (status === ChargingStationStatusEnum.Ready) {
          this.isConnected = true;
        }
      };

      const onOcppLog = (ocppLog: OcppLog) => {
        const msg = `${
          ocppLog.messageDirection === OcppMessageDirection.FromCentralSystem
            ? '↓'
            : '↑'
        } ${Utils.formatDate(new Date())} ${ocppLog.action}: ${JSON.stringify(
          ocppLog.payload
        )}`;
        console.log(msg);
        this.consoleLines.push(msg);
      };

      const onOcppError = (
        errorCode: ChargingStationOcppErrorCodeEnum,
        errorDescription: string
      ) => {
        console.log(
          `errorCode: ${errorCode} | errorDescription: ${errorDescription}`
        );
      };

      const onError = (
        errorCode: ChargingStationErrorCodeEnum,
        errorDetails: unknown
      ) => {
        if (errorDetails instanceof Error) {
          console.error(
            `errorCode: ${errorCode} | Error.stack: ${errorDetails.stack}`
          );
        } else {
          console.log(
            `errorCode: ${errorCode} | errorDetails: ${JSON.stringify(
              errorDetails
            )}`
          );
        }
      };

      this.chargingStation = new ChargingStation({
        config,
        onStatusChange,
        onOcppLog,
        onOcppError,
        onError,
      });

      await this.chargingStation.powerOn();
    }
  }

  async onAuthorize() {
    const response = await this.chargingStation?.authorize(this.idTag);
    this.isAuthorized =
      response?.idTagInfo.status === OcppAuthorizeStatusEnum.Accepted;
  }

  async onStartTransaction() {
    await this.chargingStation!.startTransaction({
      idTag: this.idTag,
    });
  }

  async onStopTransaction() {
    await this.chargingStation!.stopTransaction({});
  }

  async onHeartbeat() {
    await this.chargingStation!.heartbeat();
  }

  async onStatusNotification() {
    await this.chargingStation!.statusNotification(1, this.statusNotification);
  }

  async onMeterValues() {
    if (this.chargingStation) {
      this.chargingStation.setPowerLimitKw(this.chargeRate);
      this.chargingStation.setMeterValuesIntervalSeconds(
        this.meterValuesInterval
      );
      if (this.chargingStation.isTransactionActive()) {
        this.chargingStation.startSendingMeterValues();
      }
    }
  }

  async onDataTransfer() {
    const payload: DataTransferRequest = {
      vendorId: 'rus.avt.cp',
      messageId: 'GetChargeInstruction',
      data: '',
    };
    await this.chargingStation!.dataTransfer(payload);
  }

  async onCustomAction() {
    const payloadValue = this.payloadText;
    const payload = JSON.parse(payloadValue) as OcppRequestPayloadType;
    await this.chargingStation!.sendAction(this.action, payload);
  }

  onClearConsole() {
    this.consoleLines = [];
  }

  async testCs() {
    // const settings: ChargingStationConfig = {
    //   centralSystemUrl: 'ws://localhost:9000/6GRVG2PR8N7SR',
    //   connectToCentralSystemTimeoutSeconds: 1,
    //   maxPowerKw: 200,
    //   enableConsoleLog: false,
    //   retryBootNotificationDefaultWaitSeconds: 10,
    //   // Official OCPP configurations
    //   chargePointVendor: 'LRT e-mobility GmbH',
    //   chargePointModel: 'Smart Vibe',
    //   chargePointSerialNumber: 'avt.001.13.1',
    //   chargeBoxSerialNumber: 'avt.001.13.1.01',
    //   firmwareVersion: '1.4.0',
    //   iccid: '',
    //   imsi: '',
    //   meterType: 'AVT NQC-ACDC',
    //   meterSerialNumber: 'avt.001.13.1.01',
    // };
    // const onStatusChange = (status: ChargingStationStatusEnum) => {
    //   console.log('status:', JSON.stringify(status));
    // };
    // const onError = (
    //   errorCode: ChargingStationErrorCodeEnum,
    //   errorDetails: unknown
    // ) => {
    //   if (errorDetails instanceof Error) {
    //     console.error(
    //       `errorCode: ${errorCode} | Error.stack: ${errorDetails.stack}`
    //     );
    //   } else {
    //     console.log(
    //       `errorCode: ${errorCode} | errorDetails: ${JSON.stringify(
    //         errorDetails
    //       )}`
    //     );
    //   }
    // };
    // const cs = new ChargingStation(settings, onStatusChange, onError);
    // await cs.powerOn();
    // const authorizeResponse = await cs.authorize('B4A63CDF');
    // console.log('authorizeResponse:', authorizeResponse);
    // await cs.powerOff();
  }
}
