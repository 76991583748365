export enum ChargingStationStatusEnum {
  PowerOff = 'PowerOff',
  PowerOn = 'PowerOn',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  Booting = 'Booting',
  Booted = 'Booted',
  Rejected = 'Rejected',
  Faulted = 'Faulted',
  Ready = 'Ready',
  NoConfigCentralSystemUrl = 'NoConfigCentralSystemUrl',
  StartTransactionNotAuthorized = 'StartTransactionNotAuthorized',
  RemoteStartTransactionNotAuthorized = 'RemoteStartTransactionNotAuthorized',
}
