export const mockConfiguration = {
  "configuration_key": [
    {
      "key": "RCMBDelta",
      "readonly": false,
      "value": "0"
    },
    {
      "key": "RCMBTransactionMaxRMS",
      "readonly": true,
      "value": " 0.0"
    },
    {
      "key": "RCMBTransactionMaxDC",
      "readonly": true,
      "value": " 0.1"
    },
    {
      "key": "InvalidRFIDFilter",
      "readonly": false,
      "value": "1"
    },
    {
      "key": "EnforceSecureRFID",
      "readonly": false,
      "value": "0"
    },
    {
      "key": "ChargeBoxSerialNumber",
      "readonly": false,
      "value": "2102511975"
    },
    {
      "key": "PowerLineVoltage",
      "readonly": false,
      "value": "230"
    }
  ],
  "unknown_key": []
}
