import { OcppActionEnum } from '../enums/ocpp-action-enum';
import { OcppRequestPayloadType } from './ocpp-request-payload.type';
import { OcppResponsePayloadType } from './ocpp-response-payload.type';
import { OcppMessageTypeId } from '../enums/ocpp-message-type-id.enum';
import { OcppCallErrorCodeEnum } from '../enums/ocpp-call-error-code.enum';

// [<MessageTypeId>, "<UniqueId>", "<Action>", {<Payload>}]
export type OcppCallType = [
  OcppMessageTypeId,
  string,
  OcppActionEnum,
  OcppRequestPayloadType
];

// [<MessageTypeId>, "<UniqueId>", {<Payload>}]
export type OcppCallResultType = [
  OcppMessageTypeId,
  string,
  OcppResponsePayloadType
];

// [<MessageTypeId>, "<UniqueId>", "<errorCode>", "<errorDescription>", {<errorDetails>}]
export type OcppCallErrorType = [
  OcppMessageTypeId,
  string,
  OcppCallErrorCodeEnum,
  string,
  object
];

export type OcppAnyCallType =
  | OcppCallType
  | OcppCallResultType
  | OcppCallErrorType;

export function getOcppCallMessageTypeId(
  callData: OcppAnyCallType
): OcppMessageTypeId {
  return callData[0];
}

export function getOcppCallUniqueId(callData: OcppAnyCallType): string {
  return callData[1];
}

export function getOcppCallAction(callData: OcppCallType): OcppActionEnum {
  return callData[2];
}

export function getOcppCallPayload(
  callData: OcppCallType
): OcppRequestPayloadType {
  return callData[3];
}

export function getOcppCallResultPayload(
  callData: OcppCallResultType
): OcppResponsePayloadType {
  return callData[2];
}

export function getOcppCallErrorCode(
  callData: OcppCallErrorType
): OcppCallErrorCodeEnum {
  return callData[2];
}

export function getOcppCallErrorDescription(
  callData: OcppCallErrorType
): string {
  return callData[3];
}

export function getOcppCallErrorDetails(callData: OcppCallErrorType): object {
  return callData[4];
}
