import { v4 as uuid } from 'uuid';

export class Utils {
  public static make2Digits(value: string) {
    if (value.length < 2) {
      value = '0' + value.slice(-2);
    }
    return value;
  }

  public static formatDate(date: Date) {
    const year = date.getFullYear();
    let month = this.make2Digits(String(date.getMonth() + 1));
    let day = this.make2Digits(String(date.getDate()));
    let hour = this.make2Digits(String(date.getHours()));
    let minute = this.make2Digits(String(date.getMinutes()));
    let second = this.make2Digits(String(date.getSeconds()));
    return (
      year +
      '-' +
      month +
      '-' +
      day +
      'T' +
      hour +
      ':' +
      minute +
      ':' +
      second +
      'Z'
    );
  }

  public static getNewMessageId() {
    return uuid();
  }

  public static secondsToMilliseconds(seconds: number): number {
    return seconds * 1000;
  }
}
