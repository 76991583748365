import { v4 as uuid } from 'uuid';
import { ParamRequiredError } from '../core/exceptions';
import { AuthorizeRequest } from '../ocpp/dto/Authorize';
import { AuthorizeResponse } from '../ocpp/dto/AuthorizeResponse';
import { BootNotificationRequest } from '../ocpp/dto/BootNotification';
import { BootNotificationResponse } from '../ocpp/dto/BootNotificationResponse';
import { HeartbeatResponse } from '../ocpp/dto/HeartbeatResponse';
import { MeterValuesRequest } from '../ocpp/dto/MeterValues';
import { MeterValuesResponse } from '../ocpp/dto/MeterValuesResponse';
import { RemoteStartTransactionRequest } from '../ocpp/dto/RemoteStartTransaction';
import { RemoteStopTransactionRequest } from '../ocpp/dto/RemoteStopTransaction';
import { StartTransactionRequest } from '../ocpp/dto/StartTransaction';
import { StartTransactionResponse } from '../ocpp/dto/StartTransactionResponse';
import { StatusNotificationRequest } from '../ocpp/dto/StatusNotification';
import { StatusNotificationResponse } from '../ocpp/dto/StatusNotificationResponse';
import { StopTransactionRequest } from '../ocpp/dto/StopTransaction';
import { StopTransactionResponse } from '../ocpp/dto/StopTransactionResponse';
import { OcppActionEnum } from '../ocpp/enums/ocpp-action-enum';
import { OcppAuthorizeStatusEnum } from '../ocpp/enums/ocpp-authorize-status.enum';
import { OcppBootNotificationStatus } from '../ocpp/enums/ocpp-boot-notification-status';
import { OcppMessageDirection } from '../ocpp/enums/ocpp-message-direction.enum';
import { OcppStatusEnum } from '../ocpp/enums/ocpp-status.enum';
import {
  OcppWebSocket,
  OcppWebSocketNotInstantiatedError,
  OcppWebSocketNotOpenError,
} from '../ocpp/ocpp-web-socket';
import { OcppLog } from '../ocpp/types/ocpp-log';
import { OcppCallMessageType } from '../ocpp/types/ocpp-message-types';
import { OcppRequestPayloadType } from '../ocpp/types/ocpp-request-payload.type';
import { OcppResponsePayloadType } from '../ocpp/types/ocpp-response-payload.type';
import { Utils } from '../utils';
import { ChargingStationConfig } from './charging-station-config';
import { ChargingStationErrorCodeEnum } from './charging-station-error.enum';
import { ChargingStationOcppErrorCodeEnum } from './charging-station-ocpp-error.enum';
import { ChargingStationStatusEnum } from './charging-station-status.enum';
import { DataTransferResponse } from '../ocpp/dto/DataTransferResponse';
import { DataTransferRequest } from '../ocpp/dto/DataTransfer';
import { RemoteStartTransactionResponse } from '../ocpp/dto/RemoteStartTransactionResponse';
import { ChargingStationStatusError } from './charging-station-exceptions';
import { GetConfigurationResponse } from '../ocpp/dto/GetConfigurationResponse';
import { mockConfiguration } from '../ocpp/mocks/mock-test-configuration';
import { TriggerMessageRequest } from '../ocpp/dto/TriggerMessage';
import { TriggerMessageResponse } from '../ocpp/dto/TriggerMessageResponse';
import { SetChargingProfileResponse } from '../ocpp/dto/SetChargingProfileResponse';
import { OcppAvailabilityStatusEnum } from '../ocpp/enums/ocpp-availability-status.enum';
import { ChangeAvailabilityRequest } from '../ocpp/dto/ChangeAvailability';
import { OcppChangeAvailabilityEnum } from '../ocpp/enums/ocpp-change-availability.enum';

const ACCEPTED = 'Accepted';
const REJECTED = 'Rejected';
const NOT_IMPLEMENTED = 'NotImplemented';

export class ChargingStation {
  constructor(
    private props: {
      config: ChargingStationConfig;
      onStatusChange?: (status: ChargingStationStatusEnum) => void;
      onOcppLog?: (log: OcppLog) => void;
      onOcppError?: (
        errorCode: ChargingStationOcppErrorCodeEnum,
        errorDescription: string
      ) => void;
      onError?: (
        errorCode: ChargingStationErrorCodeEnum,
        errorObject: Error
      ) => void;
    }
  ) {
    if (!props) {
      throw new ParamRequiredError('props');
    }
    if (!props.config) {
      throw new ParamRequiredError('props.config');
    }
    props.config.enableConsoleLog = props.config.enableConsoleLog ?? true;
    this.internalStatus = ChargingStationStatusEnum.PowerOff;
  }

  public async powerOn(): Promise<void> {
    return new Promise((resolve) => {
      this.setInternalStatus(ChargingStationStatusEnum.PowerOn);

      // Try to connect to the central system in the background...
      (async () => {
        let connected: boolean;
        try {
          connected = await this.connectToCentralSystem();
        } catch (error) {
          connected = false;
          this.emitError(
            ChargingStationErrorCodeEnum.ConnectToCentralSystem,
            error as Error
          );
        }

        // If connected then boot the system.
        if (connected) {
          try {
            await this.bootNotification();
          } catch (error) {
            this.setInternalStatus(ChargingStationStatusEnum.Faulted);
            this.emitError(
              ChargingStationErrorCodeEnum.BootNotification,
              error as Error
            );
          }
        } else {
          this.ocppWebSocket = undefined;
        }

        // Resolve the powerOn command.
        resolve();
      })();
    });
  }

  public async powerOff(): Promise<void> {
    this.stopSendingHeartbeat();
    if (this.ocppWebSocket) {
      await this.ocppWebSocket.close(ChargingStationStatusEnum.PowerOff);
      this.ocppWebSocket = undefined;
    }
    this.setInternalStatus(ChargingStationStatusEnum.PowerOff);
  }

  public async connectToCentralSystem(): Promise<boolean> {
    const { config } = this.props;
    if (!config.centralSystemUrl) {
      this.setInternalStatus(
        ChargingStationStatusEnum.NoConfigCentralSystemUrl
      );
      return false;
    }

    this.ocppWebSocket = new OcppWebSocket({
      centralSystemUrl: config.centralSystemUrl,
      connectToWebSocketTimeoutSeconds:
        this.props.config.connectToCentralSystemTimeoutSeconds,
      enableConsoleLog: config.enableConsoleLog,
      onWebSocketClose: () => {
        this.setInternalStatus(ChargingStationStatusEnum.Disconnected);
      },
      onWebSocketError: () => {
        this.setInternalStatus(ChargingStationStatusEnum.Faulted);
      },
      onCentralSystemCall: (m) => this.onCentralSystemCall(m),
    });

    try {
      await this.ocppWebSocket.connect();
      this.setInternalStatus(ChargingStationStatusEnum.Connected);
      return true;
    } catch (error) {
      this.setInternalStatus(ChargingStationStatusEnum.Faulted);
      this.emitError(
        ChargingStationErrorCodeEnum.ConnectToWebSocket,
        error as Error
      );
      return false;
    }
  }

  public setPowerLimitKw(powerLimitKw: number) {
    if (powerLimitKw <= 0) {
      alert(`Power Limit (${powerLimitKw} kW) must be greater than 0 kW.`);
      return;
    }

    const maxPowerKw = this.props.config.maxPowerKw;
    if (powerLimitKw > maxPowerKw) {
      alert(
        `Power Limit (${powerLimitKw} kW) cannot be greater than Max Power (${maxPowerKw} kW).`
      );
      return;
    }

    this.props.config.powerLimitKw = powerLimitKw;
  }

  public setMeterValuesIntervalSeconds(interval: number) {
    if (interval <= 0) {
      alert(
        `Meter values interval (${interval}) must be greater than 0 seconds.`
      );
      return;
    }

    this.props.config.meterValuesIntervalSeconds = interval;
  }

  public startSendingMeterValues() {
    if (this.meterValuesIntervalId) {
      clearInterval(this.meterValuesIntervalId);
    }

    const interval = this.getMeterValuesIntervalSeconds();

    this.consoleLog(`Setting MeterValues interval to ${interval} seconds.`);

    this.meterValuesSampleDate = new Date();
    this.meterValuesIntervalId = setInterval(async () => {
      await this.meterValues();
    }, Utils.secondsToMilliseconds(interval));
  }

  public isTransactionActive(): boolean {
    return (this.currentTransactionId ?? 0) > 0;
  }

  // Operations Initiated by Charging Station
  public async authorize(idTag: string): Promise<AuthorizeResponse> {
    const requestPayload: AuthorizeRequest = { idTag };
    return await this.sendCallToCentralSystem(
      OcppActionEnum.Authorize,
      requestPayload
    );
  }

  public async bootNotification(): Promise<BootNotificationResponse | null> {
    const { config } = this.props;
    const requestPayload: BootNotificationRequest = {
      chargePointVendor: config.chargePointVendor,
      chargePointModel: config.chargePointModel,
      chargePointSerialNumber: config.chargePointSerialNumber,
      chargeBoxSerialNumber: config.chargeBoxSerialNumber,
      firmwareVersion: this.getFirmwareVersion(),
      iccid: config.iccid,
      imsi: config.imsi,
      meterType: config.meterType,
      meterSerialNumber: config.meterSerialNumber,
    };

    let response: BootNotificationResponse;
    try {
      // Clear any boot notification to be retried,
      // because we are already booting.
      if (this.retryBootNotificationIntervalId) {
        clearTimeout(this.retryBootNotificationIntervalId);
        this.retryBootNotificationIntervalId = undefined;
      }
      this.setInternalStatus(ChargingStationStatusEnum.Booting);
      response = await this.sendCallToCentralSystem<BootNotificationResponse>(
        OcppActionEnum.BootNotification,
        requestPayload
      );
    } catch (error) {
      this.setInternalStatus(ChargingStationStatusEnum.Faulted);
      this.lastBootNotificationStatus = OcppBootNotificationStatus.Rejected;
      this.emitError(
        ChargingStationErrorCodeEnum.BootNotification,
        error as Error
      );
      return null;
    }

    this.lastBootNotificationStatus =
      response.status as OcppBootNotificationStatus;
    if (response.status === OcppBootNotificationStatus.Accepted) {
      this.setInternalStatus(ChargingStationStatusEnum.Booted);
      this.synchronizeInternalClock(response.currentTime);
      this.startSendingHeartbeat(response.interval);
      this.setInternalStatus(ChargingStationStatusEnum.Ready);

      // On successful boot, inform the Central System that this charging station is available.
      await this.statusNotification(0, OcppStatusEnum.Available);
    } else if (response.status === OcppBootNotificationStatus.Rejected) {
      const waitSeconds =
        typeof response.interval === 'number' && response.interval > 0
          ? response.interval
          : this.props.config.retryBootNotificationDefaultWaitSeconds;
      this.retryBootNotificationIntervalId = setTimeout(() => {
        (async () => {
          this.retryBootNotificationIntervalId = undefined;
          await this.bootNotification();
        })();
      }, Utils.secondsToMilliseconds(waitSeconds));
    }

    return response;
  }

  public async dataTransfer(
    payload: DataTransferRequest
  ): Promise<DataTransferResponse> {
    return this.sendCallToCentralSystem(OcppActionEnum.DataTransfer, payload);
  }

  public async heartbeat(): Promise<HeartbeatResponse> {
    return await this.sendCallToCentralSystem(OcppActionEnum.Heartbeat, {});
  }

  public async startTransaction(params: {
    idTag: string;
    connectorId?: number;
  }): Promise<StartTransactionResponse> {
    const connectorId = this.resolveStartTransactionConnectorId(
      params.connectorId
    );
    const requestPayload: StartTransactionRequest = {
      idTag: params.idTag,
      connectorId,
      timestamp: this.getTimestamp(),
      meterStart: this.getCurrentMeterEnergyWh(connectorId),
      //reservationId: 0,
    };

    const response =
      await this.sendCallToCentralSystem<StartTransactionResponse>(
        OcppActionEnum.StartTransaction,
        requestPayload
      );

    if (response.idTagInfo.status === OcppAuthorizeStatusEnum.Accepted) {
      if (response.transactionId > 0) {
        this.currentTransactionId = response.transactionId;
        await this.statusNotification(connectorId, OcppStatusEnum.Charging);
        this.startSendingMeterValues();
      }
    } else {
      this.setInternalStatus(
        ChargingStationStatusEnum.StartTransactionNotAuthorized
      );
      await this.statusNotification(connectorId, OcppStatusEnum.Available);
    }

    return response;
  }

  public async stopTransaction(params: {
    transactionId?: number;
    idTag?: string;
  }): Promise<StopTransactionResponse | null> {
    const transactionId = params.transactionId ?? this.currentTransactionId;
    if (!transactionId) {
      return null;
    }

    const connectorId = this.getTransactionConnectorId(transactionId);

    const requestPayload: StopTransactionRequest = {
      idTag: params.idTag,
      transactionId,
      timestamp: this.getTimestamp(),
      meterStop: this.getCurrentMeterEnergyWh(connectorId),
    };

    let response: StopTransactionResponse | null = null;

    try {
      response = await this.sendCallToCentralSystem<StopTransactionResponse>(
        OcppActionEnum.StopTransaction,
        requestPayload
      );
    } catch (error) {
      this.emitError(
        ChargingStationErrorCodeEnum.StopTransaction,
        error as Error
      );
    } finally {
      this.stopSendingMeterValues();
      this.currentTransactionId = undefined;
    }

    if (this.changeAvailabilityScheduled) {
      const ocppStatusToNotify =
        this.changeAvailabilityScheduled.type ===
        OcppChangeAvailabilityEnum.Operative
          ? OcppStatusEnum.Available
          : OcppStatusEnum.Unavailable;

      const connectorId = this.changeAvailabilityScheduled.connectorId ?? 0;
      this.changeAvailabilityScheduled = undefined; // Clear the schedule

      setTimeout(async () => {
        await this.statusNotification(connectorId, ocppStatusToNotify);
      }, 0);
    }

    return response;
  }

  public async statusNotification(
    connectorId: number,
    status: OcppStatusEnum
  ): Promise<StatusNotificationResponse> {
    this.currentOcppStatus = status;
    const requestPayload: StatusNotificationRequest = {
      connectorId,
      errorCode: 'NoError',
      //info: null,
      status,
      timestamp: this.getTimestamp(),
      //vendorId: null,
      //vendorErrorCode: null,
    };
    return await this.sendCallToCentralSystem(
      OcppActionEnum.StatusNotification,
      requestPayload
    );
  }

  public async meterValues(): Promise<MeterValuesResponse> {
    this.calculateCurrentMeterEnergy();

    const powerRateKw = this.getPowerRateKw();
    const meterEnergyWh = this.getCurrentMeterEnergyWh();
    const transactionId = this.currentTransactionId;

    this.consoleLog(
      `MeterValues | powerRateKw: ${powerRateKw} | currentMeterEnergyWh: ${meterEnergyWh} | transactionId: ${transactionId}`
    );

    const connectorId = transactionId
      ? this.getTransactionConnectorId(transactionId)
      : 0;

    const requestPayload: MeterValuesRequest = {
      connectorId,
      transactionId,
      meterValue: [
        {
          timestamp: this.getTimestamp(),
          sampledValue: [
            {
              value: powerRateKw + '',
              unit: 'kW',
            },
            {
              value: meterEnergyWh + '',
              unit: 'Wh',
            },
          ],
        },
        {
          timestamp: this.getTimestamp(),
          sampledValue: [
            {
              value: '0.007471051',
              unit: 'kW',
            },
          ],
        },
        {
          timestamp: this.getTimestamp(),
          sampledValue: [
            {
              value: '230',
              measurand: 'Voltage',
              phase: 'L1',
              unit: 'V',
            },
          ],
        },
        {
          timestamp: this.getTimestamp(),
          sampledValue: [
            {
              value: '230',
              measurand: 'Voltage',
              phase: 'L2',
              unit: 'V',
            },
          ],
        },
        {
          timestamp: this.getTimestamp(),
          sampledValue: [
            {
              value: '230',
              measurand: 'Voltage',
              phase: 'L3',
              unit: 'V',
            },
          ],
        },
        {
          timestamp: this.getTimestamp(),
          sampledValue: [
            {
              value: '0.033725526',
              measurand: 'Current.Export',
              phase: 'L1',
              unit: 'A',
            },
          ],
        },
        {
          timestamp: this.getTimestamp(),
          sampledValue: [
            {
              value: '0',
              measurand: 'Current.Export',
              phase: 'L2',
              unit: 'A',
            },
          ],
        },
        {
          timestamp: this.getTimestamp(),
          sampledValue: [
            {
              value: '0',
              measurand: 'Current.Export',
              phase: 'L3',
              unit: 'A',
            },
          ],
        },
      ],
    };

    return await this.sendCallToCentralSystem(
      OcppActionEnum.MeterValues,
      requestPayload
    );
  }

  public async sendAction(
    action: OcppActionEnum,
    payload: OcppRequestPayloadType
  ): Promise<OcppResponsePayloadType> {
    return await this.sendCallToCentralSystem(action, payload);
  }

  // Operations Initiated by Central System
  private onCentralSystemCall(message: OcppCallMessageType) {
    this.emitOcppLog({
      messageDirection: OcppMessageDirection.FromCentralSystem,
      messageUniqueId: message.messageUniqueId,
      action: message.action,
      payload: message.payload,
    });

    switch (message.action) {
      case OcppActionEnum.CancelReservation:
        break;
      case OcppActionEnum.ChangeAvailability:
        (async () => await this.onChangeAvailability(message))();
        break;
      case OcppActionEnum.ChangeConfiguration:
        break;
      case OcppActionEnum.ClearCache:
        (async () => await this.onClearCache(message))();
        break;
      case OcppActionEnum.ClearChargingProfile:
        break;
      case OcppActionEnum.DataTransfer:
        break;
      case OcppActionEnum.GetCompositeSchedule:
        break;
      case OcppActionEnum.GetConfiguration:
        (async () => await this.sendGetConfiguration(message))();
        break;
      case OcppActionEnum.GetDiagnostics:
        break;
      case OcppActionEnum.GetLocalListVersion:
        break;
      case OcppActionEnum.RemoteStartTransaction:
        (async () => await this.onRemoteStartTransaction(message))();
        break;
      case OcppActionEnum.RemoteStopTransaction:
        (async () => await this.onRemoteStopTransaction(message))();
        break;
      case OcppActionEnum.ReserveNow:
        break;
      case OcppActionEnum.Reset:
        (async () => await this.onReset(message))();
        break;
      case OcppActionEnum.SendLocalList:
        break;
      case OcppActionEnum.SetChargingProfile:
        (async () => await this.onSetChargingProfile(message))();
        break;
      case OcppActionEnum.TriggerMessage:
        (async () => await this.onTriggerMessage(message))();
        break;
      case OcppActionEnum.UnlockConnector:
        (async () => await this.onUnlockConnector(message))();
        break;
      case OcppActionEnum.UpdateFirmware:
        break;
      default:
        //TODO: what to do?
        break;
    }
  }
  private async onSetChargingProfile(
    message: OcppCallMessageType
  ): Promise<void> {
    await this.sendCallResultToCentralSystem(
      message.messageUniqueId,
      message.action,
      { status: ACCEPTED } as SetChargingProfileResponse
    );
  }

  private async onChangeAvailability(
    message: OcppCallMessageType
  ): Promise<void> {
    const sendResponse = async (status: OcppAvailabilityStatusEnum) => {
      await this.sendCallResultToCentralSystem(
        message.messageUniqueId,
        message.action,
        { status }
      );
    };

    const payload = message.payload as ChangeAvailabilityRequest;
    const availabilityType = payload.type as OcppChangeAvailabilityEnum;

    let ocppStatusToNotify: OcppStatusEnum;

    // In the event that Central System requests Charge Point to change to a status it is already in,
    // Charge Point SHALL respond with availability status Accepted.
    if (
      availabilityType === OcppChangeAvailabilityEnum.Operative &&
      (this.isAvailable() || this.isCharging())
    ) {
      ocppStatusToNotify = this.currentOcppStatus;
    } else if (this.isCharging()) {
      this.changeAvailabilityScheduled = payload;
      await sendResponse(OcppAvailabilityStatusEnum.Scheduled);
      return;
    } else if (availabilityType === OcppChangeAvailabilityEnum.Operative) {
      ocppStatusToNotify = OcppStatusEnum.Available;
    } else {
      ocppStatusToNotify = OcppStatusEnum.Unavailable;
    }

    await sendResponse(OcppAvailabilityStatusEnum.Accepted);

    setTimeout(async () => {
      await this.statusNotification(
        payload.connectorId ?? 0,
        ocppStatusToNotify
      );
    }, 0);
  }

  private async onClearCache(message: OcppCallMessageType): Promise<void> {
    await this.sendCallResultToCentralSystem(
      message.messageUniqueId,
      message.action,
      { status: ACCEPTED }
    );
  }

  private async onReset(message: OcppCallMessageType): Promise<void> {
    await this.sendCallResultToCentralSystem(
      message.messageUniqueId,
      message.action,
      { status: ACCEPTED }
    );
    await this.bootNotification();
    location.reload();
  }

  private async onRemoteStartTransaction(
    message: OcppCallMessageType
  ): Promise<void> {
    const payload = message.payload as RemoteStartTransactionRequest;

    const can = this.canRemoteStartTransaction(payload);
    if (can) {
      await this.sendCallResultToCentralSystem(
        message.messageUniqueId,
        message.action,
        { status: ACCEPTED } as RemoteStartTransactionResponse
      );
    } else {
      await this.sendCallResultToCentralSystem(
        message.messageUniqueId,
        message.action,
        { status: REJECTED } as RemoteStartTransactionResponse
      );
      return;
    }

    const authResponse = await this.authorize(payload.idTag);
    if (authResponse.idTagInfo.status === OcppAuthorizeStatusEnum.Accepted) {
      setTimeout(() => {
        (async () => {
          await this.startTransaction({
            idTag: payload.idTag,
            connectorId: payload.connectorId,
          });
        })();
      }, 2000); // Try to simulate a real CS.
    } else {
      this.setInternalStatus(
        ChargingStationStatusEnum.RemoteStartTransactionNotAuthorized
      );
      this.emitOcppError(
        ChargingStationOcppErrorCodeEnum.RemoteStartTransactionNotAuthorized,
        `Remote start transaction was not authorized for idTag (${payload.idTag}).`
      );
      await this.statusNotification(
        payload.connectorId ?? 0,
        OcppStatusEnum.Available
      );
    }
  }

  private async onRemoteStopTransaction(
    message: OcppCallMessageType
  ): Promise<void> {
    const payload = message.payload as RemoteStopTransactionRequest;
    await this.sendCallResultToCentralSystem(
      message.messageUniqueId,
      message.action,
      { status: ACCEPTED }
    );
    await this.stopTransaction({
      transactionId: payload.transactionId,
    });
    this.stopSendingMeterValues();
  }

  private async onTriggerMessage(message: OcppCallMessageType): Promise<void> {
    const payload = message.payload as TriggerMessageRequest;
    const requestedMessage = payload.requestedMessage as OcppActionEnum;

    const isMessageImplemented = [
      OcppActionEnum.BootNotification,
      OcppActionEnum.Heartbeat,
      OcppActionEnum.MeterValues,
      OcppActionEnum.StatusNotification,
    ].includes(requestedMessage);

    if (isMessageImplemented) {
      await this.sendCallResultToCentralSystem(
        message.messageUniqueId,
        message.action,
        { status: ACCEPTED } as TriggerMessageResponse
      );
    } else {
      await this.sendCallResultToCentralSystem(
        message.messageUniqueId,
        message.action,
        { status: NOT_IMPLEMENTED } as TriggerMessageResponse
      );
      return;
    }

    switch (requestedMessage) {
      case OcppActionEnum.BootNotification:
        await this.bootNotification();
        break;
      case OcppActionEnum.Heartbeat:
        await this.heartbeat();
        break;
      case OcppActionEnum.MeterValues:
        await this.meterValues();
        break;
      case OcppActionEnum.StatusNotification:
        await this.triggerStatusNotification(payload.connectorId);
        break;
    }
  }

  private async triggerStatusNotification(
    connectorId?: number
  ): Promise<StatusNotificationResponse> {
    const status = this.currentTransactionId
      ? OcppStatusEnum.Charging
      : OcppStatusEnum.Available;
    return await this.statusNotification(connectorId ?? 0, status);
  }

  private async sendGetConfiguration(
    message: OcppCallMessageType
  ): Promise<void> {
    const payload = message.payload as RemoteStopTransactionRequest;
    await this.sendCallResultToCentralSystem(
      message.messageUniqueId,
      message.action,
      {
        configurationKey: mockConfiguration.configuration_key,
      } as GetConfigurationResponse
    );
  }

  private async onUnlockConnector(message: OcppCallMessageType): Promise<void> {
    await this.sendCallResultToCentralSystem(
      message.messageUniqueId,
      message.action,
      { status: ACCEPTED }
    );
  }

  public async sendCallToCentralSystem<T extends OcppResponsePayloadType>(
    action: OcppActionEnum,
    payload: OcppRequestPayloadType
  ): Promise<T> {
    this.assertWebSocket();

    // Check if the internal status can send call to the central system.
    const canSendCall =
      action === OcppActionEnum.BootNotification ||
      this.lastBootNotificationStatus !== OcppBootNotificationStatus.Rejected;
    if (!canSendCall) {
      throw new ChargingStationStatusError(
        `Can not send call to central system in the OcppBootNotificationStatus: ${this.lastBootNotificationStatus}.`
      );
    }

    const messageUniqueId = this.getNewMessageUniqueId();

    this.emitOcppLog({
      messageDirection: OcppMessageDirection.ToCentralSystem,
      action,
      payload,
      messageUniqueId,
    });

    const response = await this.ocppWebSocket!.sendCallToCentralSystem<T>({
      messageUniqueId,
      action,
      payload,
    });

    this.emitOcppLog({
      messageDirection: OcppMessageDirection.FromCentralSystem,
      action,
      payload: response,
      messageUniqueId,
    });

    return response;
  }
  // End <Operations Initiated by Central System>

  private async sendCallResultToCentralSystem(
    messageUniqueId: string,
    action: OcppActionEnum,
    payload: OcppResponsePayloadType
  ) {
    this.emitOcppLog({
      messageDirection: OcppMessageDirection.ToCentralSystem,
      action,
      payload,
      messageUniqueId,
    });
    await this.ocppWebSocket!.sendCallResultToCentralSystem(
      messageUniqueId,
      payload
    );
  }

  private setInternalStatus(status: ChargingStationStatusEnum) {
    this.internalStatus = status;
    if (this.props.onStatusChange) {
      this.props.onStatusChange(this.internalStatus);
    }
  }

  private emitOcppLog(log: OcppLog) {
    if (this.props.onOcppLog) {
      this.props.onOcppLog(log);
    }
  }

  private emitOcppError(
    errorCode: ChargingStationOcppErrorCodeEnum,
    errorDescription: string
  ) {
    if (this.props.onOcppError) {
      this.props.onOcppError(errorCode, errorDescription);
    }
  }

  private emitError(
    errorCode: ChargingStationErrorCodeEnum,
    errorObject: Error
  ) {
    if (this.props.onError) {
      this.props.onError(errorCode, errorObject);
    }
  }

  private synchronizeInternalClock(currentTime: string) {
    //TODO: Implement synchronizeInternalClock
    console.warn(`Implement synchronizeInternalClock: ${currentTime}`);
  }

  private startSendingHeartbeat(interval: number) {
    this.consoleLog(`Setting heartbeat interval to ${interval} seconds.`);
    this.heartbeatIntervalId = setInterval(() => {
      (async () => {
        return await this.heartbeat();
      })();
    }, Utils.secondsToMilliseconds(interval));
  }

  private stopSendingHeartbeat() {
    if (this.heartbeatIntervalId) {
      this.consoleLog('Removing heartbeat interval.');
      clearInterval(this.heartbeatIntervalId);
    }
  }

  private stopSendingMeterValues() {
    this.consoleLog('Removing MeterValues interval.');
    if (this.meterValuesIntervalId) {
      clearInterval(this.meterValuesIntervalId);
    }
  }

  private getPowerRateKw(): number {
    //TODO: support power load management.
    const { config } = this.props;
    return config.powerLimitKw ?? config.maxPowerKw;
  }

  private getMeterValuesIntervalSeconds(): number {
    return this.props.config.meterValuesIntervalSeconds ?? 10;
  }

  private assertWebSocket() {
    if (!this.ocppWebSocket) {
      throw new OcppWebSocketNotInstantiatedError();
    } else if (!this.ocppWebSocket.isConnected()) {
      throw new OcppWebSocketNotOpenError();
    }
  }

  private consoleLog(message: string) {
    if (this.props.config.enableConsoleLog) {
      console.log(message);
    }
  }

  private getFirmwareVersion(): string | undefined {
    //TODO: Support firmware update.
    return this.props.config.firmwareVersion;
  }

  private getTimestamp(): string {
    //TODO: Synchronize with central system clock.
    return Utils.formatDate(new Date());
  }

  private resolveStartTransactionConnectorId(connectorId?: number) {
    if (connectorId) {
      return connectorId;
    }
    //TODO: Select a connector that has an EV connected.
    return 1;
  }

  private getCurrentMeterEnergyWh(connectorId?: number): number {
    //TODO: Implement connector meter value.
    return Math.round(this.currentMeterEnergyWh ?? 0);
  }

  private getTransactionConnectorId(transactionId: number): number {
    //TODO: Implement connector meter value.
    return 1;
  }

  private getNewMessageUniqueId(): string {
    return uuid();
  }

  private canRemoteStartTransaction(
    request: RemoteStartTransactionRequest
  ): boolean {
    //TODO: Decide based on the request data...
    return !this.currentTransactionId;
  }

  private isAvailable(): boolean {
    return this.currentOcppStatus === OcppStatusEnum.Available;
  }

  private isCharging(): boolean {
    return this.currentOcppStatus === OcppStatusEnum.Charging;
  }

  public calculateCurrentMeterEnergy(): number {
    if (!this.meterValuesSampleDate) {
      return 0;
    }

    const oldMeterEnergyWh = this.getCurrentMeterEnergyWh();

    const newSampleDate = new Date();
    const diffMilliseconds =
      newSampleDate.getTime() - this.meterValuesSampleDate.getTime();
    const diffSeconds = Math.round(diffMilliseconds / 1000);

    const powerRateKw = this.getPowerRateKw();
    const powerRateW = powerRateKw * 1000;
    const energyWh = powerRateW * (diffSeconds / 3600);
    this.currentMeterEnergyWh = oldMeterEnergyWh + energyWh;
    this.meterValuesSampleDate = newSampleDate;

    return this.currentMeterEnergyWh;
  }

  private internalStatus = ChargingStationStatusEnum.PowerOff;
  private ocppWebSocket?: OcppWebSocket = undefined;
  private heartbeatIntervalId?: any = undefined;
  private meterValuesIntervalId?: any = undefined;
  private retryBootNotificationIntervalId?: any = undefined;
  private currentTransactionId?: number = undefined;
  private currentMeterEnergyWh?: number = undefined;
  private meterValuesSampleDate?: Date = undefined;
  private currentOcppStatus: OcppStatusEnum = OcppStatusEnum.Unavailable;
  private lastBootNotificationStatus?: OcppBootNotificationStatus = undefined;
  private changeAvailabilityScheduled?: ChangeAvailabilityRequest = undefined;
}
