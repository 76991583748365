<h1>LRT OCPP 1.6 Charging Station Simulator</h1>

<!-- Central Station URL -->
<label for="centralSystemUrlInput">Central System URL</label
><input
  type="text"
  id="centralSystemUrlInput"
  [(ngModel)]="centralSystemUrl"
  placeholder="Central System URL"
/>

<!-- ID Tag -->
<label for="idTagInput">ID Tag</label
><input id="idTagInput" type="text" placeholder="Tag" [(ngModel)]="idTag" />

<!-- Actions -->
<hr />
<h2>Actions</h2>
<button
  (click)="onConnectOrDisconnect()"
  [ngStyle]="{
    background: isConnected ? 'green' : blueColor
  }"
>
  {{ isConnected ? "Disconnect" : "Connect" }}
</button>
<button
  *ngIf="isConnected"
  (click)="onAuthorize()"
  [ngStyle]="{
    background:
      isAuthorized === undefined ? blueColor : isAuthorized ? 'green' : 'red'
  }"
>
  Authorize
</button>
<button *ngIf="isConnected" (click)="onStartTransaction()">
  Start Transaction
</button>
<button *ngIf="isConnected" (click)="onStopTransaction()">
  Stop Transaction
</button>
<button *ngIf="isConnected" (click)="onHeartbeat()">Heartbeat</button>

<!-- Status Notification -->
<div *ngIf="isConnected">
  <hr />
  <h2>Status Notification</h2>
  <select [(ngModel)]="statusNotification">
    <option value="Available">Available</option>
    <option value="Preparing">Preparing</option>
    <option value="Charging">Charging</option>
    <option value="SuspendedEVSE">SuspendedEVSE</option>
    <option value="SuspendedEV">SuspendedEV</option>
    <option value="Finishing">Finishing</option>
    <option value="Reserved">Reserved</option>
    <option value="Unavailable">Unavailable</option>
    <option value="Faulted">Faulted</option>
  </select>
  <button (click)="onStatusNotification()">Send Status Notification</button>
</div>

<!-- Meter Values -->
<div *ngIf="isConnected">
  <hr />
  <label for="meterValuesIntervalInput">Meter Values Interval</label
  ><input
    type="text"
    id="meterValuesIntervalInput"
    placeholder="Meter Values interval in seconds"
    [(ngModel)]="meterValuesInterval"
  />
  <label for="chargeRateInput">Charge Rate</label
  ><input
    type="text"
    id="chargeRateInput"
    placeholder="Charge rate in kW"
    [(ngModel)]="chargeRate"
  />
  <button (click)="onMeterValues()">Send Meter Values</button>
</div>

<!-- Data Transfer -->
<button *ngIf="isConnected" (click)="onDataTransfer()">Data Transfer</button>

<div *ngIf="isConnected">
  <hr />
  <h2>Custom Message</h2>
  <label for="actionInput">Action</label
  ><input
    type="text"
    id="actionInput"
    placeholder="Action"
    [(ngModel)]="action"
  />
  <label for="payloadTextArea">Payload</label>
  <br />
  <textarea
    id="payloadTextArea"
    rows="6"
    cols="50"
    [(ngModel)]="payloadText"
  ></textarea>
  <button (click)="onCustomAction()">Send Custom Action</button>
</div>

<h2>Console</h2>
<button
  *ngIf="consoleLines && consoleLines.length > 0"
  (click)="onClearConsole()"
>
  Clear Console
</button>

<ul id="console">
  <li *ngFor="let item of consoleLines">{{ item }}</li>
</ul>
