import { OcppActionEnum } from './enums/ocpp-action-enum';
import { OcppMessageTypeId } from './enums/ocpp-message-type-id.enum';
import {
  OcppCallType,
  OcppCallResultType,
  OcppCallErrorType,
  OcppAnyCallType,
  getOcppCallMessageTypeId,
  getOcppCallUniqueId,
  getOcppCallAction,
  getOcppCallPayload,
  getOcppCallResultPayload,
  getOcppCallErrorCode,
  getOcppCallErrorDescription,
  getOcppCallErrorDetails,
} from './types/ocpp-call-types';
import {
  OcppMessageBaseType,
  OcppCallMessageType,
  OcppCallResultMessageType,
  OcppCallErrorMessageType,
  OcppAnyCallMessageType,
} from './types/ocpp-message-types';
import { OcppRequestPayloadType } from './types/ocpp-request-payload.type';
import { ParamRequiredError } from '../core/exceptions';
import { OcppResponsePayloadType } from './types/ocpp-response-payload.type';
import { Utils } from '../utils';

export class OcppWebSocket {
  constructor(
    private props: {
      centralSystemUrl: string;
      connectToWebSocketTimeoutSeconds?: number;
      enableConsoleLog?: boolean;

      // WebSocket callbacks
      onWebSocketOpen?: (event: Event) => any;
      onWebSocketClose?: (event: CloseEvent) => any;
      onWebSocketError?: (event: Event) => any;
      onWebSocketMessage?: (event: MessageEvent<any>) => any;

      // Central System callbacks
      onCentralSystemCall?: (message: OcppCallMessageType) => void;
      onCentralSystemCallResult?: (message: OcppCallResultMessageType) => void;
      onCentralSystemCallError?: (message: OcppCallErrorMessageType) => void;
    }
  ) {
    if (!props) {
      throw new ParamRequiredError('props');
    }
    if (!props.centralSystemUrl) {
      throw new ParamRequiredError('props.centralSystemUrl');
    }
    props.enableConsoleLog = props.enableConsoleLog ?? true;
  }

  public async connect(): Promise<WebSocket> {
    return new Promise((resolve, reject) => {
      const WS_AT_URL = `WebSocket at URL ${this.props.centralSystemUrl}`;

      this.consoleLog(`Connecting to ${WS_AT_URL}...`);
      this.webSocket = new WebSocket(this.props.centralSystemUrl, [
        'ocpp1.6',
        'ocpp1.5',
      ]);

      // Timeout
      let rejectTimeoutId: number | undefined = undefined;
      if (
        typeof this.props.connectToWebSocketTimeoutSeconds === 'number' &&
        this.props.connectToWebSocketTimeoutSeconds > 0
      ) {
        rejectTimeoutId = setTimeout(() => {
          reject(
            new OcppWebSocketConnectTimeoutError(
              this.props.connectToWebSocketTimeoutSeconds!
            )
          );
        }, Utils.secondsToMilliseconds(this.props.connectToWebSocketTimeoutSeconds)) as unknown as number;
      }

      const clearRejectTimeout = () => {
        if (rejectTimeoutId) {
          clearTimeout(rejectTimeoutId);
        }
      };

      // onopen
      let isWebSocketOpened = false;
      this.webSocket.onopen = (event) => {
        isWebSocketOpened = true;
        clearRejectTimeout();
        this.consoleLog(`Connected to ${WS_AT_URL}.`);
        resolve(this.webSocket!);
        if (this.props.onWebSocketOpen) {
          this.props.onWebSocketOpen(event);
        }
      };

      // onclose
      this.webSocket.onclose = (event) => {
        this.webSocket = null;
        if (isWebSocketOpened) {
          this.consoleLog(`Disconnected from ${WS_AT_URL}.`);
          if (this.props.onWebSocketClose) {
            this.props.onWebSocketClose(event);
          }
          if (this.closeCommandToResolve) {
            this.closeCommandToResolve(event);
            this.closeCommandToResolve = undefined;
          }
        }
      };

      // onerror
      this.webSocket.onerror = (event) => {
        this.consoleError(
          `Error with ${WS_AT_URL}. Event.type: ${event.type}.`
        );
        if (isWebSocketOpened) {
          if (this.props.onWebSocketError) {
            this.props.onWebSocketError(event);
          }
        } else {
          clearRejectTimeout();
          reject(new OcppWebSocketConnectError());
        }
      };

      // onmessage
      this.webSocket.onmessage = (e) => {
        this.onMessageFromCentralSystem(e);
        if (this.props.onWebSocketMessage) {
          this.props.onWebSocketMessage(e);
        }
      };
    });
  }

  public async close(reason: string): Promise<CloseEvent> {
    this.assertWebSocket();
    return new Promise((resolve, reject) => {
      // If the WebSocket do not close in 1 second then reject the promise.
      const timeoutId = setTimeout(() => {
        reject();
      }, 1000);
      this.closeCommandToResolve = (event: CloseEvent) => {
        clearTimeout(timeoutId);
        this.closeCommandToResolve = undefined;
        resolve(event);
      };
      this.webSocket!.close(3000, reason);
    });
  }

  public isConnected(): boolean {
    return !!this.webSocket && this.webSocket.readyState === WebSocket.OPEN;
  }

  public async sendCallToCentralSystem<
    T extends OcppResponsePayloadType
  >(params: {
    messageUniqueId: string;
    action: OcppActionEnum;
    payload: OcppRequestPayloadType;
  }): Promise<T> {
    this.assertWebSocket();
    return new Promise<T>((resolve, reject) => {
      this.ocppCallMessagePromises[params.messageUniqueId] = {
        resolve: resolve as any,
        reject,
      };
      const callData: OcppCallType = [
        OcppMessageTypeId.CALL,
        params.messageUniqueId,
        params.action,
        params.payload,
      ];
      (async () => await this.sendOcppCall(callData))();
    });
  }

  public async sendCallResultToCentralSystem(
    uniqueId: string,
    payload: OcppResponsePayloadType
  ): Promise<void> {
    const callData: OcppCallResultType = [
      OcppMessageTypeId.CALL_RESULT,
      uniqueId,
      payload,
    ];
    await this.sendOcppCall(callData);
  }

  private async sendOcppCall(callData: OcppAnyCallType): Promise<void> {
    const data = JSON.stringify(callData);
    await this.send(data);
  }

  private async send(data: string): Promise<void> {
    this.assertWebSocket();
    this.webSocket?.send(data);
  }

  private onMessageFromCentralSystem(messageEvent: MessageEvent<string>) {
    const anyCallData = JSON.parse(messageEvent.data) as OcppAnyCallType;
    const ocppAnyCallMessage = this.getOcppAnyCallMessage(anyCallData);
    if (!ocppAnyCallMessage) {
      return; // ignore.
    }

    // Check if the message is a response to a CALL from this client.
    const { messageTypeId, messageUniqueId } = ocppAnyCallMessage;
    if (messageUniqueId in this.ocppCallMessagePromises) {
      let resolved = false;
      const promiseFuncs = this.ocppCallMessagePromises[messageUniqueId];
      if (messageTypeId === OcppMessageTypeId.CALL_RESULT) {
        const callMessage = ocppAnyCallMessage as OcppCallMessageType;
        promiseFuncs.resolve(callMessage.payload);
        resolved = true;
      } else if (messageTypeId === OcppMessageTypeId.CALL_ERROR) {
        promiseFuncs.reject(ocppAnyCallMessage as OcppCallErrorMessageType);
        resolved = true;
      }

      delete this.ocppCallMessagePromises[messageUniqueId];
      if (resolved) {
        return;
      }
    }

    // If not resolved by promise then report the call.
    this.reportCentralSystemCall(ocppAnyCallMessage);
  }

  private reportCentralSystemCall(ocppAnyCallMessage: OcppAnyCallMessageType) {
    const { messageTypeId } = ocppAnyCallMessage;
    if (messageTypeId === OcppMessageTypeId.CALL) {
      if (this.props.onCentralSystemCall) {
        this.props.onCentralSystemCall(
          ocppAnyCallMessage as OcppCallMessageType
        );
      }
    } else if (messageTypeId === OcppMessageTypeId.CALL_RESULT) {
      if (this.props.onCentralSystemCallResult) {
        this.props.onCentralSystemCallResult(
          ocppAnyCallMessage as OcppCallResultMessageType
        );
      }
    } else if (messageTypeId === OcppMessageTypeId.CALL_ERROR) {
      if (this.props.onCentralSystemCallError) {
        this.props.onCentralSystemCallError(
          ocppAnyCallMessage as OcppCallErrorMessageType
        );
      }
    }
  }

  private getOcppAnyCallMessage(
    anyCallData: OcppAnyCallType
  ): OcppAnyCallMessageType | null {
    const messageTypeId = getOcppCallMessageTypeId(anyCallData);
    const messageBase: OcppMessageBaseType = {
      messageTypeId,
      messageUniqueId: getOcppCallUniqueId(anyCallData),
    };

    if (messageTypeId === OcppMessageTypeId.CALL) {
      const callData = anyCallData as OcppCallType;
      return {
        ...messageBase,
        action: getOcppCallAction(callData),
        payload: getOcppCallPayload(callData),
      } as OcppCallMessageType;
    } else if (messageTypeId === OcppMessageTypeId.CALL_RESULT) {
      const callResultData = anyCallData as OcppCallResultType;
      return {
        ...messageBase,
        payload: getOcppCallResultPayload(callResultData),
      } as OcppCallResultMessageType;
    } else if (messageTypeId === OcppMessageTypeId.CALL_ERROR) {
      const callErrorData = anyCallData as OcppCallErrorType;
      return {
        ...messageBase,
        errorCode: getOcppCallErrorCode(callErrorData),
        errorDescription: getOcppCallErrorDescription(callErrorData),
        errorDetails: getOcppCallErrorDetails(callErrorData),
      } as OcppCallErrorMessageType;
    } else {
      // IMPORTANT: SHALL ignore the MessageTypeId.
      this.consoleWarn(
        `Unknown message from Central System. MessageTypeId: ${messageTypeId}.`
      );
      return null;
    }
  }

  private assertWebSocket() {
    if (!this.webSocket) {
      throw new OcppWebSocketNotInstantiatedError();
    } else if (this.webSocket.readyState !== WebSocket.OPEN) {
      throw new OcppWebSocketNotOpenError();
    }
  }

  private consoleLog(message: string) {
    if (this.props.enableConsoleLog) {
      console.log(message);
    }
  }

  private consoleWarn(message: string) {
    if (this.props.enableConsoleLog) {
      console.warn(message);
    }
  }

  private consoleError(message: string) {
    if (this.props.enableConsoleLog) {
      console.error(message);
    }
  }

  private webSocket: WebSocket | null = null;
  private ocppCallMessagePromises: Record<
    string, // MessageUniqueId
    {
      resolve: (value: OcppResponsePayloadType) => void;
      reject: (reason: OcppCallErrorMessageType) => void;
    }
  > = {};

  private closeCommandToResolve?: (event: CloseEvent) => void = undefined;
}

export class OcppWebSocketNotInstantiatedError extends Error {
  constructor() {
    super('WebSocket is not instantiated.');
  }
}

export class OcppWebSocketNotOpenError extends Error {
  constructor() {
    super('WebSocket is not open.');
  }
}

export class OcppWebSocketConnectError extends Error {
  constructor() {
    super('Can not connect to WebSocket.');
  }
}

export class OcppWebSocketConnectTimeoutError extends Error {
  constructor(timeout: number) {
    super(`Can not connect to WebSocket in ${timeout} seconds.`);
  }
}
